import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useForm, ValidationError } from '@formspree/react'; // Import useForm and ValidationError from Formspree
import SecondNavbar from '../components/Navbar';
import { Link } from 'react-router-dom';
import {  FiArrowLeft } from 'react-icons/fi';

const ContactPage = () => {
  const [state, handleSubmit] = useForm("xqazzpda"); // Formspree form endpoint

  return (
    <div className="container mx-auto ">
      
      <Link to="/">

<FiArrowLeft className="text-black py-4 hover:bg-blue-400 hover:rounded-full  my-6 mx-4 text-6xl" />


</Link>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mx-4">
        {/* Image container on the left */}
        <div className="relative overflow-hidden bg-cover bg-center rounded-lg shadow-lg glass">
          {/* Overlay for glass effect */}
          <div className="absolute inset-0 bg-gray-200 opacity-25"></div>
          {/* Image */}
          <img src="https://img.freepik.com/free-photo/3d-render-concept-old-telephone-3d-art-design-illustration_460848-10119.jpg?t=st=1719053748~exp=1719057348~hmac=b54cc73e43b0cc53962173e8dcfbdacf440722b21e9dd1b072cd7f91233d1a79&w=900" alt="Consulting" className="absolute inset-0 w-full h-full object-cover object-center" />
        </div>
        {/* Contact form details on the right */}
        <div>
          <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
          <Formik
            initialValues={{ name: '', email: '', message: '' }}
            validationSchema={Yup.object({
              name: Yup.string().required('Name is required'),
              email: Yup.string().email('Invalid email address').required('Email is required'),
              message: Yup.string().required('Message is required'),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              handleSubmit(values); // Submit the form data to Formspree
              resetForm();
              setSubmitting(false);
            }}
          >
            <Form>
              <div className="mb-4">
                <label htmlFor="name" className="block font-semibold">Name</label>
                <Field type="text" id="name" name="name" className="border border-gray-300 rounded-md py-2 px-3 w-full" />
                <ErrorMessage name="name" component="div" className="text-red-500" />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block font-semibold">Email</label>
                <Field type="email" id="email" name="email" className="border border-gray-300 rounded-md py-2 px-3 w-full" />
                <ErrorMessage name="email" component="div" className="text-red-500" />
              </div>
              <div className="mb-4">
                <label htmlFor="message" className="block font-semibold">Message</label>
                <Field as="textarea" id="message" name="message" rows="4" className="border border-gray-300 rounded-md py-2 px-3 w-full"></Field>
                <ErrorMessage name="message" component="div" className="text-red-500" />
              </div>
              <button type="submit" disabled={state.submitting} className="bg-orange-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md">
                {state.submitting ? 'Submitting...' : 'Submit'}
              </button>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;