import React from "react";
import { Link } from "react-router-dom";

const HeroSection = () => {
  return (
    <section className="bg-white min-h-screen px-6 pt-20 lg:pt-2 md:pt-24 flex items-center">
      <div className="container mx-auto h-full px-6 py-16 flex flex-col md:flex-row items-center">
        <div className="w-full md:w-1/2 text-center md:text-left">
          <h1 className="text-5xl font-bold text-gray-800 mb-4 ">
          E & L IMPORTS LLC
          </h1>
          <h2 className="text-xl text-gray-600 font-bold py-3 ">
          EXOTIC VANILLA & SPICES
          </h2>
          <p className=" text-gray-600 text-[13px]  py-4 leading-5 mb-3">
          Experience the richness of Uganda's vanilla beans—renowned for their deep, creamy flavor and high vanillin content, cultivated in the fertile, tropical soils by dedicated farmers. Discover the true essence of premium vanilla.
            <br />
           
          </p>
          <Link
            to="/about"
            className="bg-black rounded-md pt-3 text-white px-6 py-3 text-[15px] font-semibold hover:bg-yellow-400"
          >
            Explore Now
          </Link>{" "}
        </div>
        <div className="w-full md:w-1/2 h-64 md:h-full mt-8 md:mt-0 flex justify-center items-center">
          <img
            src="https://img.freepik.com/free-vector/vanilla-ice-cream-scoop-closeup-realistic-composition-with-aromatic-dried-beans-fresh-yellow-flower_1284-31943.jpg?t=st=1718548233~exp=1718551833~hmac=abb498a4b6ddfc3a8f21e4329e037de35f3f673c4d20d31e31f69e10de297916&w=740"
            alt="Hero"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
