import React, { useState } from 'react';
import { MdClose } from 'react-icons/md';
import { HiMenu, HiHome, HiInformationCircle } from 'react-icons/hi';
import { FaShoppingCart } from 'react-icons/fa';

const ShopMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative bg-white shadow-lg">
      {/* Shop Categories */}
      <div id="shop" className="bg-gray-100 p-6">
        <h2 className="text-4xl font-extrabold mb-8 text-center text-gray-800">SHOP</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Category A */}
          <div className="relative h-[400px] bg-white rounded-lg shadow-lg overflow-hidden group hover:shadow-xl transition duration-300 ease-in-out">
            <img
              src="https://images.unsplash.com/photo-1610487512810-b614ad747572?q=80&w=2064&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="Dry Vanilla Pods"
              className="w-full h-48 object-cover"
            />
            <div className="p-6">
              <h3 className="text-2xl font-semibold mb-2 text-gray-800">A - Dry Vanilla Pods</h3>
              <p className="text-lg text-gray-700">Extraction Grade B beans 1 lb $80 each</p>
              <p className="text-sm text-gray-700">Perfect for use in baking, cooking, and homemade extracts.</p>
            </div>
          </div>

          {/* Category B */}
          <div className="relative h-[400px] bg-white rounded-lg shadow-lg overflow-hidden group hover:shadow-xl transition duration-300 ease-in-out">
            <img
              src="https://cdn.shopify.com/s/files/1/2017/8433/files/Screenshot_2023-05-28_at_4.41.34_PM_480x480.png?v=1685313733"
              alt="Gourmet Vanilla Beans"
              className="w-full h-48 object-cover"
            />
            <div className="p-6">
              <h3 className="text-2xl font-semibold mb-2 text-gray-800">B - Gourmet Vanilla Beans Grade A</h3>
              <p className="text-sm text-gray-700">Pure Organic Bourbon Gourmet - Best Ugandan Vanilla Beans</p>
              <p className="text-sm text-gray-700">Vanilla Planifolia E. G Grade A Planifolia (Bourbon) 10 beans for $14.99</p>
              <p className="text-lg text-gray-700">1 lb $120</p>
              <p className="text-sm text-gray-700">Known for their rich aroma and flavor, perfect for gourmet dishes.</p>
            </div>
          </div>

          {/* Category C */}
          <div className="relative bg-white rounded-lg shadow-lg overflow-hidden group hover:shadow-xl transition duration-300 ease-in-out">
            <img
              src="https://sambavanilla.com/215-home_default/png-vanilla-beans-xl-size.jpg"
              alt="Split Beans"
              className="w-full h-48 object-cover"
            />
            <div className="p-6">
              <h3 className="text-2xl font-semibold mb-2 text-gray-800">C - Split Beans</h3>
              <p className="text-lg text-gray-700">1 lb $100 each</p>
              <p className="text-sm text-gray-700">These beans are split for easy extraction of vanilla seeds.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopMenu;
