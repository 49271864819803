import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/footer';
import ceo from '../images/ceo.jpeg';
import CallToActionSection from '../components/calltoaction';
import OurStory from '../components/ourstory';

const AboutPage = () => {
  return (
    <div>
      {/* Hero Section */}
      <Navbar />
      <section
        className="relative h-[90vh] flex items-center justify-center text-center bg-cover bg-center"
        style={{ backgroundImage: "url('https://images.unsplash.com/photo-1610487512810-b614ad747572?q=80&w=2064&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')" }}
      >
        <div className="absolute inset-0 bg-gray-700/50 opacity-50"></div>
        <div className="relative p-10">
          <h1 className="text-6xl font-extrabold text-white mb-4">Our Story</h1>
          <p className="text-2xl text-white">The Exotic Vanilla Beans</p>
        </div>
        {/* <div className="absolute bottom-0 mb-10 flex flex-col items-center">
          <img src={ceo} alt="CEO" className="w-24 h-24 md:w-40 md:h-40 rounded-full object-cover shadow-lg transition-transform duration-300 hover:scale-105" />
          <div className="text-center text-white mt-4">
            <h2 className="text-2xl font-extrabold">Meet Our CEO</h2>
            <p className="text-sm">Our CEO, [CEO Name], leads our company with passion and dedication.</p>
          </div>
        </div> */}
      </section>

      {/* Meet the CEO Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-6">
          <div className="flex flex-col gap-8 md:flex-row items-center">
            <img src={ceo} alt="CEO" className="w-20 h-20 md:w-64 md:h-64 rounded-full object-cover shadow-lg transition-transform duration-300 hover:scale-105 mb-6 md:mb-0 aspect-square" />
            <div className="md:ml-12 text-center md:text-left">
              <h2 className="text-5xl font-extrabold text-gray-900 mb-6">Meet Our CEO</h2>
              <p className="text-gray-600 text-sm mb-4">Our CEO, [CEO Name], has over 20 years of experience in the vanilla industry. With a passion for sustainability and quality, [CEO Name] has led our company to become a trusted source of premium Ugandan vanilla beans.</p>
              <p className="text-gray-600 text-sm">Under [CEO Name]'s leadership, we have established strong relationships with local farmers and communities in Uganda, ensuring that our vanilla beans are not only of the highest quality but also ethically sourced. [CEO Name] is committed to making a positive impact through fair trade practices and sustainable farming methods.</p>
            </div>
          </div>
        </div>
      </section>




      {/* Main Content Section */}
      <section className="bg-white py-16">
        <div className="container mx-auto px-[40px]">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
            <div>
              <h2 className="text-3xl font-bold text-gray-900 mb-4">Our Mission</h2>
              <p className="text-gray-700 text-[12px] leading-6 mb-6">
                We have always enjoyed baking and so we were inspired to explore importing Vanilla with an intention to help our underserved communities in Uganda. Our dream is to make an impact in people's lives by securing a market for their products in the United States.
              </p>
              <h2 className="text-3xl font-bold text-gray-900 mb-4">Our Vision</h2>
              <p className="text-gray-700 text-[12px] leading-6 mb-6">
                We are passionate about collaborating with farmer groups and non-profit organizations in support of women and children. We would like our company to provide jobs to the community every season which translates into hundreds of farmers in Mukono District, Uganda.
              </p>
            </div>
            <div>
              <h2 className="text-3xl font-bold text-gray-900 mb-4">Our Values</h2>
              <p className="text-gray-700 text-[12px] leading-6 mb-6">
                Integrity, Excellence, and Community Support are at the core of our values. We believe in doing the right thing and always putting our community first.
              </p>
              <div className="mt-6">
                <img src="https://img.freepik.com/free-photo/puzzle-pieces-3d_1048-5015.jpg?t=st=1718714041~exp=1718717641~hmac=b0c136013cda2614dcd4262a7908b5b4e75becbfaaa2f8edce37d0854d4ed1ae&w=1380" alt="Our Team" className="w-full h-auto rounded-lg " />
              </div>
            </div>
          </div>
        </div>
      </section>

      <OurStory />

      
      <CallToActionSection />
      <Footer />
    </div>
  );
};

export default AboutPage;
