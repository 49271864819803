import React from 'react';
import { Link } from 'react-router-dom';

const ServicesSection = () => {
  const services = [
    {
      title: 'Premium Vanilla Beans',
      description: 'High-quality vanilla beans sourced from the best farms to enhance your culinary creations.',
      imgUrl: 'https://i.pinimg.com/564x/b1/9b/ca/b19bca104e4db336b6a2b808ccd41839.jpg',
      altText: 'Premium Vanilla Beans',
    },
    {
      title: 'Split Vanilla Beans',
      description: 'Split vanilla beans packed with intense flavor, perfect for extracting vanilla seeds for recipes.',
      imgUrl: 'https://i.pinimg.com/564x/3b/e8/71/3be871085640e061f67cc0d258b183f4.jpg',
      altText: 'Split Vanilla Beans',
    },
    {
      title: 'Gourmet Vanilla Beans',
      description: 'Exquisite gourmet vanilla beans with rich aroma, perfect for high-end culinary use.',
      imgUrl: 'https://i.pinimg.com/564x/50/4c/7f/504c7f0f6ad6f711c78d79cf86e84881.jpg',

      altText: 'Gourmet Vanilla Beans',
    },
  ];

  return (
    <section id='products' className="bg-gray-100 py-20">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-gray-800 text-center mb-12">Our Products</h2>
        <div className="flex flex-wrap -mx-4">
          {services.map((service, index) => (
            <div key={index} className="w-full md:w-1/3 px-4 mb-8">
              <div className="relative h-80 bg-cover bg-center rounded-lg overflow-hidden" style={{ backgroundImage: `url(${service.imgUrl})` }}>
                <img src={service.imgUrl} alt={service.altText} className="absolute inset-0 object-cover w-full h-full opacity-0" />
                <div className="absolute inset-0 bg-black bg-opacity-40"></div>
                <div className="relative p-6 bg-white bg-opacity-50 backdrop-blur-lg rounded-lg transform transition-transform duration-500 hover:translate-y-20">
                  <h3 className="text-2xl font-bold text-gray-800 mb-3">{service.title}</h3>
                  <p className="text-black mb-4">{service.description}</p>
                  <Link to="/about" className="text-[#c7b198] bg-black py-2 px-6 my-6 rounded-lg text-[14px] hover:text-yellow-300">Learn More</Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default ServicesSection;
