import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-[#5e3e1a] text-gray-200 text-[13px] py-8 px-4">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h2 className="text-xl font-bold mb-4">Company</h2>
            <ul>
              <li className="mb-2">
                <a href="#" className="hover:underline">About Us</a>
              </li>
              <li className="mb-2">
                <a href="#" className="hover:underline">Careers</a>
              </li>
              <li className="mb-2">
                <a href="#" className="hover:underline">Press</a>
              </li>
            </ul>
          </div>
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h2 className="text-xl font-bold mb-4">Support</h2>
            <ul>
              <li className="mb-2">
                <a href="#" className="hover:underline">Help Center</a>
              </li>
              <li className="mb-2">
                <a href="#" className="hover:underline">Contact Us</a>
              </li>
              <li className="mb-2">
                <a href="#" className="hover:underline">Privacy Policy</a>
              </li>
            </ul>
          </div>
          {/* <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <h2 className="text-xl font-bold mb-4">Social</h2>
            <ul className="flex">
              <li className="mr-4">
                <a href="#" className="hover:underline">
                  <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"><path d="M22 0H2C.9 0 0 .9 0 2v20c0 1.1.9 2 2 2h10v-8H8v-4h4V8c0-3.3 2.7-6 6-6h2v4h-2c-.6 0-1 .4-1 1v3h4l-1 4h-3v8h6c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2z"/></svg>
                </a>
              </li>
              <li className="mr-4">
                <a href="#" className="hover:underline">
                  <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"><path d="M12 2.2C6.6 2.2 2.2 6.6 2.2 12S6.6 21.8 12 21.8 21.8 17.4 21.8 12 17.4 2.2 12 2.2zm4.1 13h-2.4c-.2 0-.3-.1-.3-.3v-3.6c0-.2-.1-.3-.3-.3H8.4c-.2 0-.3.1-.3.3v3.6c0 .2-.1.3-.3.3H5.4c-.2 0-.3-.1-.3-.3V8.4c0-.2.1-.3.3-.3h2.4c.2 0 .3.1.3.3v1.8c0 .2.1.3.3.3h5.3c.2 0 .3-.1.3-.3V8.4c0-.2.1-.3.3-.3h2.4c.2 0 .3.1.3.3v7.5c0 .2-.1.3-.3.3z"/></svg>
                </a>
              </li>
              <li className="mr-4">
                <a href="#" className="hover:underline">
                  <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"><path d="M23.5 4.8c-.8.4-1.6.7-2.5.8.9-.5 1.6-1.4 2-2.4-.9.5-1.9.8-3 1-1-.9-2.2-1.4-3.6-1.4-2.7 0-5 2.2-5 5 0 .4 0 .7.1 1-4.1-.2-7.7-2.2-10.1-5.3-.4.6-.6 1.4-.6 2.2 0 1.6.8 3 2.1 3.8-.7 0-1.4-.2-2-.5 0 2.2 1.5 4 3.5 4.4-.7.2-1.4.3-2.2.3-.5 0-1 0-1.5-.1 1 3 3.8 5.1 7.1 5.2-2.7 2.1-6.2 3.3-9.9 3.3-.6 0-1.3 0-1.9-.1 3.5 2.3 7.5 3.6 11.9 3.6 14.3 0 22.2-11.9 22.2-22.2v-1c1.5-1.1 2.7-2.4 3.7-3.9z"/></svg>
                </a>
              </li>
            </ul>
          </div> */}
          <div className="w-full md:w-1/4">
            <h2 className="text-xl font-bold mb-4">Newsletter</h2>
            <form>
              <input 
                type="email" 
                placeholder="Enter your email" 
                className="w-full p-2 mb-4 text-black"
              />
              <button 
                type="submit" 
                className="w-full p-2 bg-white hover:bg-gray-200 text-gray-800"
              >
                Subscribe
              </button>
            </form>
          </div>
        </div>
        <div className="mt-8 text-center">
          <p className="text-sm">&copy; 2024 Company Name. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
