import React from 'react';

const CallToActionSection = () => {
  return (
    <section className="bg-slate-100 px-6 py-20">
      <div className="container mx-auto px-6 flex flex-col lg:flex-row items-center">
        <div className="lg:w-1/2 lg:pr-12">
          <h2 className="text-4xl font-bold text-[#5e3e1a] mb-6">Ready to Discover More?</h2>
          <p className="text-[15px]  text-gray-600 mb-8">Join us in exploring the finest vanilla beans and exotic spices. Whether you're a gourmet chef or a culinary enthusiast, we have the perfect products for you.</p>
          <h3 className="text-[13px]  font-bold text-gray-600 mb-2">Business Hours</h3>
          <p className="text-[12px]   text-gray-600 mb-2">Online business</p>
          <p className="text-[12px]  text-gray-600 mb-4">
            Mon – Fri
            <br />
            09:00 am – 06:00 pm
          </p>
          <p className="text-[12px] py-3  text-gray-600 mb-4">
            Sat – Sun - Closed
            
          </p>
          <a href="#contact" className="bg-black text-white px-8 py-4 rounded-md text-lg font-semibold hover:bg-yellow-400">
            Get in Touch
          </a>
        </div>
        <div className="mt-8 lg:mt-0 lg:w-1/2  h-1/4 rounded-lg">
          <iframe
          className='shadow-md rounded-lg'
            src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d787176.8730718043!2d-106.044129546978!3d39.58418635109642!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x876b80aa231f17cf%3A0x118ef4f8278a36d6!2sdenver!3m2!1d39.739235799999996!2d-104.990251!4m5!1s0x874014749b1856b7%3A0xc75483314990a7ff!2scolorado!3m2!1d39.5500507!2d-105.78206739999999!5e0!3m2!1sen!2ske!4v1718805638107!5m2!1sen!2ske"
            width="500"
            height="600"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default CallToActionSection;
