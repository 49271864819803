import React, { useState, useEffect } from 'react';
import { Link, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import Navbar from '../components/Navbar';
import HeroSection from '../components/Hero';
import ServicesSection from './products';
import CallToAction from '../components/calltoaction';
import Footer from '../components/footer';
import OurStory from './ourstory';
import VanillaSection from './Vanilla';
import ShopMenu from './Shopmenu';

const StickyArrow = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`fixed right-0 bottom-0 mb-8 mr-8 transition-opacity ${isSticky ? 'opacity-100' : 'opacity-0'}`}>
      <Link to="ourStory" spy={true} smooth={true} duration={500} offset={-100} className="bg-gray-900 w-[50%] z-90 h-[30%] text-3xl text-gray-100 shadow-inner  py-2 px-5 ">
        ↑
      </Link>
    </div>
  );
};

const Homepage = () => {
  useEffect(() => {
    Events.scrollEvent.register('begin', function() {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register('end', function() {
      console.log("end", arguments);
    });

    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  return (
    <div className="relative">
      <StickyArrow />
      <Navbar />
      <HeroSection />
      <Element name="ourStory">
        <OurStory />
      </Element>
      <Element name="services">
        <ServicesSection />
      </Element>
      <Element name="">
        <ShopMenu/>
      </Element>


     

      {/* <Element name="featuredProducts">
        <FeaturedProductsSection />
      </Element> */}
      <Element name="callToAction">
        {/* Call to Action Section */}
      <section className="bg-gray-100 text-slate-700 py-12">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-4xl font-bold mb-4">Join Us in Making a Difference</h2>
          <p className="text-lg mb-8">
            Be a part of our journey to support the community and bring high-quality vanilla products to the world.
          </p>
          <button className="bg-[#5e3e1a] text-white font-semibold py-2 px-4 rounded-full hover:bg-[#edb26f] transition">
            Get Involved
          </button>
        </div>
      </section>
      </Element>
      <Element name="footer">
        <Footer />
      </Element>
    </div>
  );
};

export default Homepage;
