import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FaTwitter, FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-white fixed w-full z-10 px-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <div className="shrink-0">
              <NavLink to="/" className="text-2xl font-bold text-gray-800">Eand L Vanilla</NavLink>
            </div>
            <div className="hidden md:flex md:space-x-8 ml-10">
              <NavLink to="/" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-[13px] font-medium">Home</NavLink>
              <NavLink to="/about" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-[13px] font-medium">About</NavLink>
              <Link to="/products" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-[13px] font-medium">Products</Link>
            </div>
          </div>
          <div className="flex items-center">
            <div className="flex gap-5 space-x-4 px-3">
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
            </div>
            <NavLink to="/contact" className="hidden md:inline-block bg-[#5e3e1a] text-white font-bold px-4 py-2 rounded-md text-[13px] hover:bg-gray-500">
              Contact Us
            </NavLink>
            <button
              className="md:hidden text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700 ml-4"
              onClick={toggleMenu}
            >
              <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"} />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className={`md:hidden fixed inset-0 bg-gray-800 bg-opacity-50 z-20 transition-opacity duration-300 ${isOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`} onClick={toggleMenu}></div>
      <div className={`md:hidden fixed top-0 right-0 w-3/4 bg-white shadow-lg h-full z-30 transform transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}>
        <div className="flex flex-col items-start p-4 space-y-4">
          <button
            className="self-end text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700"
            onClick={toggleMenu}
          >
            <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <NavLink to="/" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-lg font-medium" onClick={toggleMenu}>Home</NavLink>
          <NavLink to="/about" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-lg font-medium" onClick={toggleMenu}>About</NavLink>
          <a href="#products" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-lg font-medium" onClick={toggleMenu}>Products</a>
          <NavLink to="/contact" className="bg-[#5e3e1a] text-white px-4 py-2 rounded-md text-lg font-bold hover:bg-gray-500 w-full text-center" onClick={toggleMenu}>
            Contact Us
          </NavLink>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
