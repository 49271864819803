import React from 'react';
import { Link } from 'react-router-dom';
import { FaLeaf, FaSeedling, FaTree } from 'react-icons/fa';
import { FaStar } from 'react-icons/fa';
import Footer from '../components/footer';
import Navbar from '../components/Navbar';

const ProductsPage = () => {
  const products = [
    {
      title: 'Madagascar Bourbon Vanilla Beans',
      description: 'Sourced directly from Madagascar, these Bourbon vanilla beans are known for their deep, rich, and creamy flavor, perfect for gourmet dishes and desserts.',
      Icon: FaLeaf,
    },
    {
      title: 'Split Vanilla Beans',
      description: 'Tahitian vanilla beans are fruity and floral with hints of cherry and licorice, making them ideal for unique and bold culinary creations.',
      Icon: FaSeedling,
    },
    {
      title: 'Gourmet Vanilla Beans',
      description: 'Mexico is known as the birthplace of vanilla. Our Mexican vanilla beans are bold, dark, and have a spicy and woody aroma, perfect for chocolate-based desserts.',
      Icon: FaTree,
    },
  ];

  return (
    <div className="min-h-screen bg-gray-50 text-gray-800">
      <Navbar/>
      {/* Hero Section */}
      <section className="bg-gradient-to-r from-[#2b2208] to-[#240901] text-white py-20 text-center">
        <h1 className="text-6xl font-extrabold pt-6 mb-4">The Finest Vanilla Beans</h1>
        <p className="text-[14px] py-4 mb-8 max-w-3xl mx-auto">
          Discover the world’s best vanilla beans, handpicked from exotic locations to deliver rich, deep, and exquisite flavors for your culinary masterpieces.
        </p>
        <Link
          to="/shop"
          className="bg-white text-amber-800 py-3 px-8 rounded-lg text-lg hover:bg-gray-100 transition-colors duration-300"
        >
          Shop Now
        </Link>
      </section>

      {/* Product Highlights */}
      <section className="py-16 container mx-auto px-6 ">
       <div className='ml-8'> <h2 className="text-4xl font-bold text-amber-700 mb-4">Explore Our Vanilla Bean Collection</h2>
        <p className="text-sm text-gray-600 mb-16">
          Each vanilla bean variety offers unique flavor profiles to elevate your dishes to a gourmet level.
        </p>
</div>
        {products.map((product, index) => (
          <div key={index} className="mb-24">
            <div className="flex flex-col md:flex-row items-center">
              {/* Icon Section */}
              <div className="md:w-1/2 mb-6 md:mb-0 flex justify-center">
                <div className="bg-gray-100 p-10 rounded-full shadow-sm hover:bg-[#fda085] transition-colors duration-500">
                  <product.Icon className="text-[#c7b198] text-9xl" />
                </div>
              </div>
              <div className="md:w-1/2 md:pl-10 text-left">
                <h3 className="text-3xl font-bold text-gray-800 mb-4">{product.title}</h3>
                <p className="text-[13px] py-4 text-gray-600 mb-6">{product.description}</p>
                {/* <Link
                  to="/about"
                  className="text-[#c7b198] disabled: bg-black py-3 px-8 rounded-lg text-lg hover:bg-gray-800 transition-colors duration-300"
                >
                  Learn More
                </Link> */}
              </div>
            </div>
          </div>
        ))}
      </section>

      {/* Testimonials Section
      <section className="bg-gray-100 py-16">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold text-gray-800 mb-8">What Our Customers Say</h2>
          <div className="flex flex-col md:flex-row justify-center items-center space-y-8 md:space-y-0 md:space-x-8">
            <div className="w-full md:w-1/3 p-6 bg-white shadow-lg rounded-lg">
              <p className="text-gray-600 text-lg italic mb-4">"The best vanilla beans I've ever used! They transformed my desserts into gourmet experiences."</p>
              <div className="text-yellow-500 mb-4">
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
              </div>
              <p className="font-bold text-gray-800">– Sarah, Pastry Chef</p>
            </div>
            <div className="w-full md:w-1/3 p-6 bg-white shadow-lg rounded-lg">
              <p className="text-gray-600 text-lg italic mb-4">"These vanilla beans are a must-have in every kitchen. Their quality is unmatched."</p>
              <div className="text-yellow-500 mb-4">
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
              </div>
              <p className="font-bold text-gray-800">– John, Home Cook</p>
            </div>
            <div className="w-full md:w-1/3 p-6 bg-white shadow-lg rounded-lg">
              <p className="text-gray-600 text-lg italic mb-4">"I love the variety of vanilla beans available. It adds a special touch to all my baking."</p>
              <div className="text-yellow-500 mb-4">
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
              </div>
              <p className="font-bold text-gray-800">– Emily, Baker</p>
            </div>
          </div>
        </div>
      </section> */}

      {/* Call to Action Section */}
      <section className="bg-white text-gray-900 py-16 text-center">
        <h2 className="text-5xl font-bold mb-4">Ready to Transform Your Dishes?</h2>
        <p className="text-xl mb-8">Explore our collection and take your cooking to the next level with our premium vanilla beans.</p>
        <Link
          to="/shop"
          className="bg-amber-700 text-white py-3 px-8 rounded-lg text-lg hover:bg-gray-800 transition-colors duration-300"
        >
          Shop Now
        </Link>
      </section>
      <Footer/>
    </div>
  );
};

export default ProductsPage;
