import React, { useEffect } from 'react';
import { Element } from 'react-scroll';
import { FaTwitter, FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import 'aos/dist/aos.css'; // Import the CSS for AOS
import AOS from 'aos'; // Import AOS

const OurStory = () => {
  useEffect(() => {
    AOS.init({
      duration: 800, // Set the duration of animations (in milliseconds)
      offset: 200, // Set the offset (in pixels) from the original trigger point
      easing: 'ease-in-out', // Set the easing function for animations
      once: true // Specify whether animations should only happen once
    });
  }, []);

  const imageUrl = 'https://i.pinimg.com/564x/4c/71/4f/4c714f6805a5cd9e3197fed0da777afa.jpg'; // Replace with your image URL

  return (
    <Element name="ourStory">
      <section className="bg-gray-00 py-20" data-aos="fade-up">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto flex gap-4">
            <img src={imageUrl} alt="Vanilla" className="w-32 h-32 md:w-64 md:h-64 rounded-full object-cover aspect-square" />
            <div>
              <h2 className="text-3xl font-semibold text-gray-800 mb-6">OUR STORY</h2>
              <p className="text-[13px] leading-6  mb-8">
                <span className="font-bold">The Exotic Vanilla Beans</span> - Our journey began with a passion for baking and a desire to make a difference. We set out to explore the world of vanilla, with a mission to empower underserved communities in Uganda.
              </p>
              <p className="text-[13px] leading-6  mb-8">
                <span className="font-bold">Impact Through Collaboration</span> - We are dedicated to collaborating with local farmer groups and non-profit organizations to support women and children. By providing a market for their products in the United States, we aim to create sustainable opportunities for growth and prosperity.
              </p>
              <p className="text-[13px] leading-6  mb-8">
                <span className="font-bold">Empowering Communities</span> - Our commitment extends beyond just delivering high-quality vanilla. Every season, we provide jobs to hundreds of farmers in Mukono District, Uganda, helping to improve lives and build a brighter future.
              </p>
              <p className="text-[13px] leading-6  mb-8">
                <span className="font-bold">Join Us on Our Journey</span> - We invite you to join us on our mission to bring you the finest vanilla while making a positive impact on communities in Uganda. Together, we can create change, one vanilla bean at a time.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Element>
  );
};

export default OurStory;
