import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import AboutPage from "./pages/aboutpage";
import Homepage from './components/homepage';
import ContactPage from './components/contact';
import ProductsPage from './pages/products page';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          
          <Route path="/about" element={<AboutPage />} />
          <Route path="/" element={<Homepage/>} />
          <Route path="/contact" element={<ContactPage/>} />
          <Route path="/products" element={<ProductsPage/>} />


          
        </Routes>
      </Router>
    </div>
  );
}

export default App;
